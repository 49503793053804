import { logError } from "@/react-utils/xxl-log";
import { isNotNullOrUndefined } from "@xxl/common-utils";
import {
  BannerWithTextTypeEnum,
  type BannerWithText,
  type GridBanner,
} from "@xxl/content-api";
import HighlightedProductBanner from "react-app/src/components/Banners/HighlightedProductBanner/HighlightedProductBanner";
import ProductBanner from "react-app/src/components/Banners/ProductBanner/ProductBanner";
import RegularBanner from "react-app/src/components/Banners/RegularBanner/RegularBanner";
import ConditionalLink from "react-app/src/components/ConditionalLink/ConditionalLink";
import { resolveTextColor } from "react-app/src/components/GridComponent/GridComponent";
import type {
  BannerSize,
  ProductBannerPricing,
} from "react-app/src/components/PriceDisplays/price-display-helper";
import { withErrorBoundary } from "react-app/src/utils/WithErrorBoundary/with-error-boundary";
import {
  ExtraSmallBannerWrapper,
  MediumBannerWrapper,
  SmallBannerWrapper,
} from "./HomepageBannerWrappers.styled";

type LegacyBannerProps = {
  banners: GridBanner[] | BannerWithText[];
  size: BannerSize;
  positionInHomepage: number;
  priceData?: ProductBannerPricing[];
};

const wrapper: {
  [key in BannerSize]:
    | typeof ExtraSmallBannerWrapper
    | typeof SmallBannerWrapper
    | typeof MediumBannerWrapper;
} = {
  xs: ExtraSmallBannerWrapper,
  small: SmallBannerWrapper,
  medium: MediumBannerWrapper,
};

type BannerVariantProps = {
  banner: GridBanner | BannerWithText;
  size: BannerSize;
  positionInHomepage: number;
  priceData?: ProductBannerPricing[];
};

const BannerVariant = ({
  banner,
  priceData = [],
  size,
  positionInHomepage,
}: BannerVariantProps) => {
  const regularBanners = ["gridBanner", "banner", "extendedBanner"];
  const nonHihglightedProductBanners = [
    "xsProductBanner",
    "smallProductBanner",
    "twoColumnGridProductBanner",
  ];

  if (!isNotNullOrUndefined(banner._type)) {
    logError("Legacy banner is missing _type.");
    return null;
  }

  if (nonHihglightedProductBanners.includes(banner._type)) {
    const price = priceData.find(
      ({ productCode, type }) =>
        banner.product?.productCode === productCode && banner._type === type
    );

    if (!isNotNullOrUndefined(price)) {
      console.debug(`Banner of type "${banner._type}" has no price.`);
      return null;
    }

    // Narrowing down type, therefore explicit checks per type name required.
    if (
      price.type !== "xsProductBanner" &&
      price.type !== "smallProductBanner" &&
      price.type !== "twoColumnGridProductBanner"
    ) {
      logError(
        `Banner of type "${banner._type}" has mismacthing price type "${price.type}"`
      );
      return null;
    }

    return (
      <ProductBanner
        headline={banner.text}
        label={banner.typeText}
        subtitle={banner.subtitle}
        backgroundColor={banner.backgroundColor?.value}
        buttonLabel={banner.button}
        cheapestPrice={price.prices.cheapestInRecentPast}
        countdownTimerSettings={banner.countdownTimerSettings}
        icon={banner.icon}
        priceData={price}
        product={banner.product}
        productBrand={banner.productBrand}
        productDescription={banner.productDescription}
        productName={banner.productName}
        size={size}
        textColor={resolveTextColor(banner.textColorPicker?.title)}
        positionInHomepage={positionInHomepage}
      />
    );
  }

  if (banner._type === BannerWithTextTypeEnum.highlightedSmallProductBanner) {
    const price = priceData.find(
      ({ productCode, type }) =>
        banner.product?.productCode === productCode && banner._type === type
    );

    if (!isNotNullOrUndefined(price)) {
      console.debug(`Banner of type "${banner._type}" has no price.`);
      return null;
    }
    if (price.type !== BannerWithTextTypeEnum.highlightedSmallProductBanner) {
      logError(
        `Banner of type "${banner._type}" has mismacthing price type "${price.type}"`
      );
      return null;
    }

    return (
      <HighlightedProductBanner
        size={size}
        headline={banner.headline}
        label={banner.typeText}
        subtitle={banner.subtitle}
        textColor={resolveTextColor(banner.textColorPicker?.title)}
        product={banner.product}
        cheapestPrice={price.prices.cheapestInRecentPast}
        productName={banner.productName}
        productDescription={banner.productDescription}
        backgroundColor={banner.backgroundColor?.value}
        icon={banner.icon}
        logo={banner.logo}
        priceData={price}
        countdownTimerSettings={banner.countdownTimerSettings}
        positionInHomepage={positionInHomepage}
      />
    );
  }
  if (regularBanners.includes(banner._type)) {
    return (
      <RegularBanner
        tagline={banner.typeText}
        title={banner.text}
        description={banner.description}
        taglineField={banner.tagline}
        titleField={banner.titleField}
        descriptionField={banner.descriptionField}
        backgroundColor={banner.backgroundColor?.value}
        buttonText={banner.button}
        countdownTimerSettings={banner.countdownTimerSettings}
        hasImageOverlay={false}
        image={banner.image}
        mobileImage={banner.mobileImage}
        size={size}
        textColor={resolveTextColor(banner.textColorPicker?.title)}
        textPosition={banner.textPosition}
        url={banner.url}
        positionInHomepage={positionInHomepage}
      />
    );
  }

  console.error("LegacyBanner type not recognised: ", banner._type);
  return null;
};

const _LegacyBanners = ({
  banners,
  priceData,
  size,
  positionInHomepage,
}: LegacyBannerProps) => {
  const Wrapper = wrapper[size];

  return (
    <Wrapper>
      {banners.map((banner, idx) => {
        const url = banner.url ?? banner.product?.url;
        const hasBannerLink = isNotNullOrUndefined(url);

        return (
          <ConditionalLink url={url ?? ""} condition={hasBannerLink} key={idx}>
            <BannerVariant
              banner={banner}
              priceData={priceData}
              size={size}
              positionInHomepage={positionInHomepage}
            />
          </ConditionalLink>
        );
      })}
    </Wrapper>
  );
};

export const LegacyBanners = withErrorBoundary(_LegacyBanners);
